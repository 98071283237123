import { CUR8_SERVER } from "gatsby-env-variables";
import axios from "axios";
import { AxiosResponse } from "axios";

const getQuiz = async (): Promise<AxiosResponse> =>
  axios.get(`${CUR8_SERVER}/quiz`, {
    withCredentials: true,
  });

export default getQuiz;
