import React, { FC } from "react";
import styled from "styled-components";
import BlockedUserComponent from "../components/blockedUserComponent";

const Container = styled.div`
  display: flex;
`;
const DesktopContainer = styled.div`
  display: flex;
`;

const BlockedUserPage: FC = () => {
  return (
    <Container>
      <DesktopContainer>
        <BlockedUserComponent />
      </DesktopContainer>
    </Container>
  );
};

export default BlockedUserPage;
