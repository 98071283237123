import styled from "styled-components";
import { theme } from "@styles/theme";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100vh;
  width: 100%;
`;

export const StatusContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 67px 105px;

  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 67px;
  }
`;

export const StepsContainer = styled.div<{ steps: number }>`
  width: ${({ steps }) => `${steps * 70}px`};
  padding-bottom: 43px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors["SLATE100"]};
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DisclaimerContainer = styled.div`
  width: 100%;
  padding: 24px 104px;
  background-color: ${theme.colors["SLATE100"]};
`;

export const LeftContainer = styled.div`
  background-color: ${theme.colors["SLATE200"]};
`;

export const FlowContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 160px;
  background-color: ${theme.colors["SLATE200"]};
`;
