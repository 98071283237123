import { logException } from "@helpers/logException/logException";
import { useEffect, useState } from "react";
import getQuiz from "../helpers/getQuiz";
import { Quiz } from "../types/quiz";

type QuizPromiseReturn = {
  quiz?: Quiz;
  blockedUntil?: string;
};

type useDynamicQuizReturn = {
  quiz: Quiz;
  loading: boolean;
  error?: unknown;
  blockedUntil?: string;
  loadQuiz: () => Promise<QuizPromiseReturn>;
};

const useDynamicQuiz = (ignoreIt: boolean): useDynamicQuizReturn => {
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState<Quiz>([]);
  const [error, setError] = useState<unknown>();
  const [blockedUntil, setBlockedUntil] = useState();

  const loadQuiz = async () => {
    const result: QuizPromiseReturn = {};
    setLoading(true);
    const response = await getQuiz();

    setLoading(false);
    if (response.data.blockedUntil) {
      result.blockedUntil = response.data.blockedUntil;
      setBlockedUntil(response.data.blockedUntil);
    } else {
      result.quiz = response.data;
      setQuiz(response.data);
    }

    return result;
  };

  useEffect(() => {
    if (ignoreIt) {
      return;
    }

    try {
      loadQuiz();
    } catch (e) {
      setLoading(false);
      logException({
        tag: "quiz",
        message: "Quiz already completed",
        exception: e,
      });
      setError(e);
    }
  }, [ignoreIt]);

  return { quiz, error, loading, blockedUntil, loadQuiz };
};

export default useDynamicQuiz;
