import styled from "styled-components";
import { MEDIA_QUERIES, theme } from "@ifgengineering/component-library";

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors["SLATE200"]};
  padding: 56px 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 67px 105px;
  gap: 2rem;

  @media ${MEDIA_QUERIES.TAB_LAND} {
    padding: 67px;
  }
`;

export const CATBox = styled.div`
  border-radius: 8px;
  background-color: ${theme.colors.BLUE600};
  padding: 32px;

  & button {
    border: none;
    margin-top: 24px;
    cursor: pointer;
    width: auto;
  }
`;
