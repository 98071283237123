import React, { FC } from "react";
import {
  Container,
  ContentWrapper,
  DisclaimerContainer,
} from "@components/NavigationShell/styled";
import {
  Text,
  FilledButton,
  OutlinedButton,
} from "@ifgengineering/component-library";
import { ContentContainer, CATBox, LeftContainer } from "./styled";
import getRemainingTime from "./getRemainingTime";
import { navigate } from "gatsby";
import styled from "styled-components";
import Loading from "@components/Loading";
import useDynamicQuiz from "../../hooks/useDynamicQuiz";

const Button = styled(OutlinedButton)`
  cursor: pointer;
  & .i-icon {
    display: flex;
  }
`;

const BlockedUserComponent: FC = () => {
  const { blockedUntil, loading } = useDynamicQuiz(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <LeftContainer>
        <Button
          testId="back-button"
          width="fit-content"
          icon="LeftSmall"
          text="Back to live deals"
          height="40px"
          onClick={() => navigate("/app/deals")}
          style
        />
      </LeftContainer>
      <ContentWrapper>
        <ContentContainer>
          <Text type="H24" color="SLATE900" fontFamily="archiaregular">
            Quiz Failed
          </Text>
          <Text type="S16" color="SLATE800">
            Unfortunately you cannot sign up right now as you have incorrectly
            answered the quiz.
          </Text>
          {blockedUntil && (
            <Text type="H16" color="SLATE900">
              {getRemainingTime(blockedUntil)} remaining until you can
              re-attempt the quiz.
            </Text>
          )}
          <CATBox>
            <Text type="T24" color="WHITE" fontFamily="archiaregular">
              However, all is not lost
            </Text>
            <Text type="S16" color="WHITE">
              We offer a number of courses to help you on your investment
              journey
            </Text>
            <br />
            <FilledButton
              type="submit"
              text="View Courses"
              color="navy"
              height="48px"
              target="_blank"
              href="https://www.islamicfinanceguru.com/courses"
            />
          </CATBox>
        </ContentContainer>
        <DisclaimerContainer>
          <Text type="P12" color="SLATE700">
            Cur8 Capital is a trading name of IFG.VC Limited, which is a
            subsidiary of Islamicfinanceguru Limited. IFG.VC Limited is
            authorised and regulated by the FCA. This page has been approved as
            a financial promotion by IFG.VC Limited, which is authorised and
            regulated by the Financial Conduct Authority (No. 943736). Nothing
            on this page should be construed as financial or tax advice.
            Remember, with investments your capital is at risk. Past performance
            is not necessarily an accurate indicator of future returns. © IFG.VC
            Limited. All rights reserved
          </Text>
        </DisclaimerContainer>
      </ContentWrapper>
    </Container>
  );
};

export default BlockedUserComponent;
