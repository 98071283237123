import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

const getRemainingTime = (dateToUnblockUser: string): string => {
  const today = Date.now();
  const unblockedDate = new Date(dateToUnblockUser);

  const daysDiff = differenceInDays(unblockedDate, today);
  const hoursDiff = differenceInHours(unblockedDate, today);
  const minutesDiff = differenceInMinutes(unblockedDate, today);

  const daysText = daysDiff > 1 ? "Days" : "Day";
  const hoursText = hoursDiff > 1 ? "Hours" : "Hour";
  const minutesText = minutesDiff > 1 ? "Minutes" : "Minute";

  switch (true) {
    case hoursDiff > 24:
      return `${daysDiff} ${daysText}`;
    case hoursDiff >= 1:
      return `${hoursDiff} ${hoursText}`;
    case hoursDiff < 1:
      return `${minutesDiff} ${minutesText}`;
    default:
      return `${hoursDiff} ${hoursText}`;
  }
};

export default getRemainingTime;
